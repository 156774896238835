import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { loadCldr, registerLicense } from '@syncfusion/ej2-base';
import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'simplebar-react/dist/simplebar.min.css';
import { AuthProvider } from './auth/JwtContext';
import { MotionLazyContainer } from './components/animate';
import ScrollToTop from './components/scroll-to-top';
import { SettingsProvider, ThemeSettings } from './components/settings';
import SnackbarProvider from './components/snackbar';
import ThemeLocalization from './locales';
import './locales/i18n';
import { store } from './redux/store';
import Router from './routes';
import ThemeProvider from './theme';
loadCldr(require('cldr-data/supplemental/numberingSystems.json'), require('cldr-data/main/fr-CH/ca-gregorian.json'), require('cldr-data/main/fr-CH/numbers.json'), require('cldr-data/main/fr-CH/timeZoneNames.json'));
export default function App() {
    registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Wd0RjXHtdcXZWT2FZ');
    return (_jsx(AuthProvider, { children: _jsx(Provider, { store: store, children: _jsx(HelmetProvider, { children: _jsxs(LocalizationProvider, { dateAdapter: AdapterDateFns, children: [_jsx(SettingsProvider, { children: _jsx(BrowserRouter, { children: _jsxs(Suspense, { children: [_jsx(ScrollToTop, {}), _jsx(MotionLazyContainer, { children: _jsx(ThemeProvider, { children: _jsx(ThemeSettings, { children: _jsx(ThemeLocalization, { children: _jsx(SnackbarProvider, { children: _jsx(Router, {}) }) }) }) }) })] }) }) }), ' '] }) }) }) }));
}
